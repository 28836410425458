let EVENT_LIST = {
	"HEADER_NAV_LINK_CLICK": "Header Nav Link Click",
	"HEADER_NAV_MAIN_CTA_BUTTON": "Header Nav Main CTA Button",
	"VIDEO_DIALOG_CTA_BUTTON_CLICK": "Video Dialog CTA Button Click",
	"VIDEO_DIALOG_CTA_LINK_CLICK": "Video Dialog CTA Link Click",
	"VIDEO_PLAYER_PLAY_INITIATED": "Video Player Play Initiated",
	"VIDEO_PLAYER_PAUSE": "Video Player Play Paused",
	"VIDEO_PLAYER_END": "Video Player Play Ended"
} 
let UPDATE_TRACK_EVENT_LIST = {
	"STICKY SUBNAV": {
		"event_type": "Link Click",
		"nav_link_section": "Product Basics"
	},
	"Header Nav Link Click": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"mega_menu_column": "[runtime]",
		"mega_menu_link": "[runtime]",
		"mega_menu_tab": "[runtime]",
		"nav_link_section": "Mega Menu",
		"track_event": "mega_menu",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Header Nav Main CTA Button": {
		"click_id": "Get Started",
		"click_text": "Achieve-Web | Get Started",
		"nav_link_section": "Header",
		"track_event": "global_header",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Header Nav Sign in Link": {
		"click_id": "Sign in",
		"click_text": "Achieve-Web | Sign in",
		"nav_link_section": "Header",
		"track_event": "global_header",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Header Logo": {
		"click_id": "[runtime]",
		"click_text": "Achieve-Web | Header logo",
		"nav_link_section": "Header logo",
		"track_event": "global_header",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Footer Nav Link Click": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Footer Click",
		"track_event": "global_footer",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Footer Logo": {
		"click_id": "2022_Achieve_Logomark_RGB",
		"click_text": "Achieve-Web | 2022_Achieve_Logomark_RGB",
		"nav_link_section": "Footer",
		"track_event": "global_footer",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Home Hero CTA click": {
		"click_id": "Get started",
		"click_position": "[runtime]",
		"nav_link_section": "Home Hero",
		"track_event": "internal_campaign_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Home Madlibs submit": {
		"click_id": "[runtime]",
		"click_position": "0",
		"click_text": "[runtime]",
		"mad_lib_first_selection": "[runtime]",
		"mad_lib_second_selection": "[runtime]",
		"nav_link_section": "Mad Lib selection",
		"track_event": "internal_campaign_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Product Cards Download": {
		"click_id": "product cards",
		"click_position": "0",
		"nav_link_section": "Recommended Cards",
		"track_event": "internal_campaign_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Personalized Value Props CTA": {
		"click_id": "[runtime]",
		"click_position": "0",
		"click_text": "[runtime]",
		"nav_link_section": "[runtime]",
		"track_event": "internal_campaign_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Product Page FAQ CTA": {
		"click_id": "[runtime]",
		"click_position": "0",
		"click_text": "[runtime]",
		"nav_link_section": "FAQs",
		"track_event": "internal_campaign_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Product Page More Resources": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"mad_lib_first_selection": "[runtime]",
		"mad_lib_second_selection": "[runtime]",
		"mad_lib_card_title": "[runtime]",
		"mad_lib_card_recommended": "[runtime]",
		"nav_link_section": "Recommended Cards",
		"track_event": "internal_campaign_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Product Page Closer": {
		"click_id": "[runtime]",
		"nav_link_section": "Closer Statement",
		"track_event": "internal_campaign_click",
		"click_type": "Button Click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Member Stories Closer Statement": {
		"click_id": "Get Started",
		"click_position": "0",
		"nav_link_section": "Closer Statement",
		"track_event": "internal_campaign_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Listing Page Filter Results": {
		"click_id": "Filter Results",
		"click_position": "0",
		"nav_link_section": "Content Filters",
		"track_event": "button_click",
		"event_action": "button_click",
		"event_type": "button_click",
		"search_term": "[runtime]",
		"event_name": "[runtime]"
	},
	"Listing Page Category Filter": {
		"click_position": "0",
		"nav_link_section": "Content Filters",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click",
		"event_name": "[runtime]"
	},
	"Listing Page Clear Filter": {
		"application_version": "Prod",
		"click_id": "Clear filters",
		"click_position": "0",
		"click_text": "Achieve-Web | Clear filters",
		"nav_link_section": "Content Filters",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"Card Media Event": {
		"click_id": "card_media",
		"click_position": "0",
		"nav_link_section": "Card Media",
		"track_event": "internal_campaign_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Hero Carousel Event": {
		"click_id": "buttonCta",
		"click_position": "0",
		"hero_card_text": "buttonCta",
		"nav_link_section": "Hero Carousel",
		"track_event": "internal_campaign_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"Hero Form": {
		"click_id": "[runtime]",
		"click_position": "0",
		"form_ss_amount": "[runtime]",
		"hero_card_text": "[runtime]",
		"nav_link_section": "Hero Form",
		"track_event": "internal_campaign_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Mad Lib Result": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"mad_lib_first_selection": "[runtime]",
		"mad_lib_second_selection": "[runtime]",
		"nav_link_section": "Recommended Cards",
		"track_event": "internal_campaign_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"About Closer Statement": {
		"click_id": "about closer statement button",
		"click_position": "0",
		"nav_link_section": "About Closer Statement",
		"track_event": "internal_campaign_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Card Pres Read More": {
		"click_position": "[runtime]",
		"click_text": "Read more",
		"nav_link_section": "Content Grid",
		"track_event": "page_nav_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Pagination Event": {
		"click_id": "Achieve Web | Pagination",
		"click_position": "Pagination",
		"click_text": "Pagination",
		"nav_link_section": "Pagination",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"Card Article": {
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Content Grid",
		"track_event": "page_nav_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Recommended Card Link": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Product Cards",
		"track_event": "internal_campaign_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Carousel Nav": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Testimonials",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"Breadcrumb": {
		"click_id": "[runtime]",
		"click_position": "0",
		"nav_link_section": "Breadcrumb",
		"track_event": "page_nav_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Right Rail Read More": {
		"click_id": "[runtime]",
		"click_position": "Read the article",
		"click_text": "Achieve-Web | Read the article",
		"nav_link_section": "Suggested Content - Right Rail",
		"track_event": "page_nav_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Right Rail Title": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Suggested Content - Right Rail",
		"track_event": "page_nav_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Categories": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Categories",
		"track_event": "page_nav_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Inline Link": {
		"click_id": "[runtime]",
		"click_position": "0",
		"click_text": "[runtime]",
		"nav_link_section": "CTA Card - In-Content",
		"track_event": "page_nav_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"CTA CARD": {
		"click_id": "[runtime]",
		"click_position": "0",
		"click_text": "[runtime]",
		"nav_link_section": "[runtime]",
		"track_event": "page_nav_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"FAQ ACCORDION EXPANDED": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"faq_id": "[runtime]",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"FAQ ACCORDION LESS/MORE": {
		"click_id": "[runtime]",
		"click_position": "0",
		"click_text": "[runtime]",
		"form_ss_amount": "[runtime]",
		"hero_card_text": "[runtime]",
		"nav_link_section": "FAQs",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"FAQ SEE ALL": {
		"click_id": "See all FAQs",
		"click_text": "See all FAQs",
		"nav_link_section": "FAQs",
		"track_event": "page_nav_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"PRODUCT FORM": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Product Form",
		"track_event": "button_click",
		"event_action": "button_click",
		"event_type": "button_click",
		"form_ss_homeowner": "[runtime]",
		"click_type": "Button Click"
	},
	"PRODUCT FORM CTA": {
		"click_id": "[runtime]",
		"click_position": "0",
		"click_text": "[runtime]",
		"click_type": "[runtime]",
		"nav_link_section": "Product Form",
		"track_event": "internal_campaign_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"PRODUCT DETAILS": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_type": "[runtime]",
		"click_url": "[runtime]",
		"nav_link_section": "Product Details",
		"track_event": "internal_campaign_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"PRODUCT DETAILS LINK": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_type": "[runtime]",
		"click_url": "[runtime]",
		"nav_link_section": "Product Details",
		"track_event": "link_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"ARTICLE LINK": {
		"nav_link_section": "Article",
		"track_event": "link_click",
		"click_type": "Link Click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"DTI SUBMIT": {
		"track_event": "[runtime]",
		"event_action": "[runtime]",
		"form_ss_monthly_expenses": "[runtime]",
		"form_ss_monthly_income": "[runtime]",
		"form_ss_amount": "[runtime]",
		"nav_link_section": "Tool Form"
	},
	"DTI CTA CLICK": {
		"track_event": "internal_campaign_click",
		"form_ss_monthly_expenses": "[runtime]",
		"form_ss_monthly_income": "[runtime]",
		"form_ss_amount": "[runtime]",
		"nav_link_section": "Tool Scenario CTA",
		"tool_response": "[runtime]"
	},
	"DTI API RESPONSE": {
		"form_ss_monthly_expenses": "[runtime]",
		"form_ss_monthly_income": "[runtime]",
		"form_ss_amount": "[runtime]",
		"nav_link_section": "Tool API Response",
		"tool_response": "[runtime]",
		"track_event": "tool_response"
	},
	"Video Dialog CTA Button Click": {
		"click_id": "Video Dialog CTA Button Click",
		"click_text": "[runtime]",
		"click_url": "[runtime]",
		"track_event": "button_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Video Dialog CTA Link Click": {
		"click_id": "Video Dialog CTA Link Click",
		"click_text": "[runtime]",
		"click_url": "[runtime]",
		"track_event": "link_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"Video Player Play Initiated": {
		"click_id": "Video Player Play Initiated",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"Video Player Play Paused": {
		"click_id": "Video Player Play Paused",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"Video Player Play Ended": {
		"click_id": "Video Player Play Ended",
		"track_event": "ui_click",
		"event_action": "ui_click",
		"event_type": "ui_click"
	},
	"Recomended Cards Carousel Link": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Recommended Cards",
		"track_event": "link_click",
		"event_action": "link_click",
		"event_type": "link_click"
	},
	"AR STAT BUTTON": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Social & Outcome Stats",
		"track_event": "button_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Homepage Skinny Form Result": {
		"click_type": "Link Click",
		"nav_link_section": "Recommended Cards",
		"event_action": "link_click"
	},
	"Homepage Skinny Form Result Buttons": {
		"click_type": "Button Click",
		"nav_link_section": "Recommended Cards",
		"track_event": "internal_campaign_click",
		"event_action": "button_click"
	},
	"AAL More Resources Carousel": {
		"nav_link_section": "More Resources Carousel",
		"event_action": "link_click",
		"event_type": "link_click",
		"track_event": "link_click"
	},
	"Home More Resources Carousel": {
		"nav_link_section": "Testimonials",
		"click_type": "button_click",
		"track_event": "button_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Homepage Product Form": {
		"click_id": "[runtime]",
		"click_position": "[runtime]",
		"click_text": "[runtime]",
		"nav_link_section": "Product Form",
		"click_type": "button_click",
		"track_event": "button_click",
		"event_action": "button_click",
		"event_type": "button_click"
	},
	"Subnav Link": {
		"nav_link_section": "Subnav",
		"event_action": "link_click",
		"event_type": "link_click",
		"track_event": "link_click"
	},
	"PRODUCT DETAIL DISCLOSURE": {
		"click_id": "disclosures",
		"click_text": "disclosures",
		"click_type": "Link Click",
		"click_url": "[runtime]",
		"event_action": "link_click",
		"nav_link_section": "Product Details",
		"track_event": "link_click"
	},
	"DAT WAIT LINK": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_type": "Link Click",
		"click_url": "[runtime]",
		"event_action": "link_click",
		"nav_link_section": "Tool If You Wait",
		"track_event": "internal_campaign_click"
	},
	"DAT BUTTON": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_type": "Button Click",
		"click_url": "[runtime]",
		"event_action": "button_click",
		"nav_link_section": "Tool CTA",
		"track_event": "internal_campaign_click"
	},
	"DAT SELECT FIELD": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"form_ss_amount": "[runtime]",
		"click_type": "UI Click",
		"event_action": "ui_click",
		"nav_link_section": "Tool Form",
		"track_event": "ui_click"
	},
	"PHONE CALL CLICK HEADER": {
		"click_type": "Phone Click",
		"event_action": "button_click",
		"nav_link_section": "Header",
		"track_event": "global_header"
	},
	"Product Hero Phone": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_type": "Phone Click",
		"click_url": "[runtime]",
		"nav_link_section": "Hero",
		"track_event": "button_click"
	},
	"Hero V2 Phone": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_type": "Phone Click",
		"click_url": "[runtime]",
		"nav_link_section": "Hero",
		"track_event": "button_click"
	},
	"PHONE CALL CLICK": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_type": "Phone Click",
		"click_url": "[runtime]",
		"nav_link_section": "Hero",
		"track_event": "button_click"
	},
	"Post Social Profile": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_position": "[runtime]",
		"click_type": "Phone Click",
		"nav_link_section": "Author Social Links"
	},
	"Product Details Phone": {
		"click_id": "[runtime]",
		"click_text": "[runtime]",
		"click_type": "Phone Click",
		"click_url": "[runtime]",
		"nav_link_section": "Product Details",
		"track_event": "link_click"
	}
} 
export { EVENT_LIST, UPDATE_TRACK_EVENT_LIST }